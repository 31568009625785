<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main cancel-schedule">
        <section class="section m-t-10">
            <div class="back-button-wrapper" @click="onClickClose">
                <img
                    class="m-r-2 back-button"
                    :src="require(`@/assets/images/icons/close.png`)"
                    width="24px"
                    height="24px"
                />
                <span class="f-18 m-l-12 c-black">약속 취소</span>
            </div>
            <div class="title">
                <div class="text" v-html="'내 약속 내용'" />
            </div>
            <div class="content">
                <div class="desc li-dot m-b-16">일시 : {{ this.scheduleTime }}</div>
                <div class="desc li-dot m-b-16">
                    지역 : {{ options.schedule.schedule_place || this.options.schedule.meet_place }}
                </div>
                <div class="desc li-dot m-b-16">
                    <div class="place-wrap">
                        <div class="m-r-4">장소 : {{ options.schedule.restaurant_name || '미정' }}</div>
                        <div v-if="options.schedule.restaurant_name !== null" class="place-map">
                            <img
                                :src="require('@/assets/images/icons/location_black.png')"
                                alt=""
                                widtt="16"
                                height="16"
                            />
                            <span @click="showMap">지도보기</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section m-t-12 reason-input">
            <div class="title">
                <div class="text" v-html="'취소 사유'" />
            </div>
            <ul class="reason flex-fill p-t-25 p-l-10 p-r-10">
                <li
                    @click="onClickItem(item)"
                    class="item flex-row items-center flex-between"
                    :class="{ selected: item.$$selected }"
                    :key="item.id"
                    v-for="item in reasonItems"
                >
                    <div v-html="$translate(item.name)" />
                </li>
                <div class="reason flex-fill p-t-10" v-if="isSelected === true">
                    <TextareaWithX
                        v-model="inputValue"
                        placeholder="취소 사유를 입력해주세요."
                        :textareaRows="3"
                        :ref="'comment'"
                        :setClass="'content-area m-0'"
                    />
                </div>
            </ul>

            <div class="content">
                <div v-if="options.check !== undefined && me.id === schedule_cancel_user" class="desc m-t-16">
                    <div class="desc li-dot m-t-16">약속 취소에 대한 책임은 본인에게 있습니다.</div>
                    <div class="desc li-dot m-t-16">약속 취소는 어떤 경우에도 상대방의 기분이 상하기 마련입니다.</div>
                    <div class="desc li-dot m-t-16 c-red">선택하신 내용은 상대방에게 전달되지 않습니다.</div>
                </div>
                <div v-else-if="options.check !== undefined && me.id !== schedule_cancel_user">
                    <div class="desc li-dot m-t-16">약속 취소에 대한 책임은 상대방에게 있습니다.</div>
                    <div class="desc li-dot m-t-16 c-red">선택하신 내용은 상대방에게 전달되지 않습니다.</div>
                </div>
                <div v-else>
                    <div class="desc li-dot m-t-16">약속 취소는 어떤 경우에도 상대방 기분이 상하기 마련입니다.</div>
                    <div class="desc li-dot m-t-16">
                        약속 취소 사유를 최대한 구체적으로 적어주셔야 매니저가 상대방이 이해할 수 있도록 잘 말씀드릴 수
                        있습니다.
                    </div>
                </div>
            </div>
        </section>
        <BottomButton @click="submit" :disabled="disabled" :label="'약속 취소하기'" />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalCancelSchedule',
    props: ['options'],
    data: () => ({
        disabled: true,
        isSelected: false,
        inputValue: '',
        schedule_cancel_user: null,
        reasonItems: [
            {
                id: 0,
                name: '개인 사정/일정',
                $$selected: false,
            },
            {
                id: 1,
                name: '회사 업무/일정',
                $$selected: false,
            },
            {
                id: 2,
                name: '질병/부상',
                $$selected: false,
            },
            {
                id: 3,
                name: '교제하는 사람이 생김',
                $$selected: false,
            },
            {
                id: 4,
                name: '상대방이 약속 진행하지 않음/느림',
                $$selected: false,
            },
            {
                id: 5,
                name: '단순 변심',
                $$selected: false,
            },
            {
                id: 6,
                name: '기타',
                $$selected: false,
            },
        ],
    }),
    mounted() {
        if (this.options.check !== undefined) {
            this.reasonItems = [
                { id: 7, name: '상대방과 일정을 조율하기가 어려워요.', $$selected: false },
                { id: 8, name: '상대방과 만날 마음이 사라졌어요.', $$selected: false },
                { id: 9, name: '개인 사정으로 당분간 여유가 없을 것 같아요.', $$selected: false },
                { id: 10, name: '기타', $$selected: false },
            ]
        }
        this.schedule_cancel_user = this.options.chat.schedule_change.requestor_id
    },
    methods: {
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.options.schedule.restaurant_url,
            })
        },
        onClickItem(item) {
            this.reasonItems.forEach(o => this.$set(o, '$$selected', false))
            this.$set(item, '$$selected', !item.$$selected)

            this.selectedValue = [item]

            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }
            this.isSelected = item.id === 10 && item.$$selected
        },
        async onClickClose() {
            const idx = await this.$modal.basic({
                body: '약속 취소가 완료되지 않았습니다. <br />원래대로 약속을 진행하시겠습니까?',
                buttons: [
                    { label: '약속 취소하기', class: 'btn-default' },
                    { label: '원래대로 약속 진행', class: 'btn-primary' },
                ],
            })
            if (idx === 1) {
                this.$emit('close')

                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#FAF0E1',
                        bottom: '#FFF9EF',
                    },
                })
            }
        },
        async submit() {
            if (this.selectedValue[0].id === 4) {
                await this.$modal.basic({
                    body:
                        '약속 일정, 지역을 보낸 후에 48시간 안에 상대방이 약속을 진행하지 않는 경우, 약속은 자동으로 취소되고 상대방 귀책으로 인정됩니다. 잠시만 기다려주세요. (알림을 꺼 놓는 회원분들이 있어 시간이 걸릴 때가 있으니 양해 부탁드립니다. 상대방에게는 확인을 요청하는 SMS를 발송하였습니다.)',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
            } else {
                if (this.options.check !== undefined) {
                    let message = ''
                    if (this.me.id === this.schedule_cancel_user) {
                        message = `취소를 요청하면 매니저가 확인 후, 최종 취소 처리 해드립니다. 부득이한 사유가 아닌 경우 취소 페널티 수수료가 부과될 수 있습니다. <br/><br/> 페널티 수수료를 내지 않기 위해, 취소 요청을 하지 않은채 No Show하거나, 약속일 전날 열린 채팅방을 통해 상대방에게 약속 취소를 통보하고 고객센터에 알리지 않은 경우 훨씬 높은 수준의 페널티 수수료가 부과되며, 서비스 이용이 제한될 수 있습니다.<br/> <br/> 확인을 클릭하시면, 채팅방은 종료됩니다.`
                    } else {
                        message = `약속 취소에 대한 책임은 상대방에게 있습니다. <br/><br/> 상대방에게 약속 취소를 안내해드린 후, 취소 패널티 수수료를 부과할 예정입니다. <br/><br/> 확인을 클릭하시면, 채팅방은 종료됩니다.`
                    }

                    const idx = await this.$modal.basic({
                        body: message,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: '약속 취소하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        const payload = {
                            id: this.options.schedule.id,
                            user_id: this.me.id,
                            status: 'cancel',
                            date_confirmed: this.options.schedule.date_confirmed,
                            meet_place: this.original_meet_place,
                            restaurant_name: this.options.schedule.restaurant_name,
                            restaurant_url: this.options.schedule.restaurant_url,
                            reason: this.inputValue,
                        }
                        try {
                            await scheduleService.changeScheduleConfirm(payload)
                            const index = await this.$modal.basic({
                                body: '약속 취소와 함께, 채팅방이 종료되었습니다.',
                                buttons: [
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-default',
                                    },
                                ],
                            })
                            if (index === 0) {
                                this.$router.go(-1)
                                this.$router.go(-1)
                                this.$router.push({ name: 'ScheduleListPage' })
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                } else {
                    const res = await this.$modal.custom({
                        component: 'ModalConfirmSchedule',
                        options: {
                            schedule: this.options.schedule,
                            reason: this.selectedValue[0].name,
                        },
                    })

                    if (res === 'canceled') {
                        this.$emit('close', 'canceled')
                    }
                }
            }
        },
    },
    computed: {
        scheduleTime() {
            const date = new Date(this.options.schedule.date_confirmed)
            const dayArr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            const scheduleTime = `${date.getMonth() + 1}월 ${date.getDate()}일 ${this.$translate(
                dayArr[this.$moment(date).day() % 7]
            )}요일 ${date.getHours()}시 ${date.getMinutes().toString().padEnd(2, '0')}분`

            return scheduleTime
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>
<style scoped lang="scss">
.cancel-schedule {
    overflow-y: auto;

    .section {
        margin-bottom: 48px;
        padding: 0 16px;
        color: black;

        .back-button-wrapper {
            padding: 0;
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }

        ul {
            margin-bottom: 27px;

            li {
                @include center;
                padding: 15px;
                color: #888888;
                size: 14px;
                background: white;
                // margin-bottom: 8px;
                border-radius: 8px;
                border: 1px solid #efdfd2;
                font-weight: 400;

                &.selected {
                    color: #151360;
                    border: 1px solid #151360;
                    font-weight: 600;
                }
            }
        }

        .content {
            font-size: 16px;
            .type-btn {
                font-size: 14px;
                color: $grey-06;
                padding-left: 16px;
                border: 1px solid $grey-02;

                &.selected {
                    border: 1px solid $blue-primary !important;
                    background-color: #f0f8ff;
                    color: $blue-primary;
                }
            }
            .desc {
                &.li-dot {
                    display: list-item;
                    align-items: flex-start;
                    margin-left: 16px;
                }
            }

            .place-map {
                margin-left: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 69px;
                width: fit-content;
                height: 24px;
                background: $grey-03;
                border-radius: 100px;
                color: #111;
            }
            .place-wrap {
                display: flex;
                align-items: center;
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
